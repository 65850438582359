"use strict";

var _preact = require("preact");

var _core = require("@uppy/core");

var _companionClient = require("@uppy/companion-client");

var _providerViews = require("@uppy/provider-views");

const packageJson = {
  "version": "1.1.2"
};

const locale = require("./locale.js");

class Zoom extends _core.UIPlugin {
  constructor(uppy, opts) {
    super(uppy, opts);
    this.id = this.opts.id || 'Zoom';

    _companionClient.Provider.initPlugin(this, opts);

    this.title = this.opts.title || 'Zoom';

    this.icon = () => (0, _preact.h)("svg", {
      "aria-hidden": "true",
      focusable: "false",
      width: "32",
      height: "32",
      viewBox: "0 0 32 32"
    }, (0, _preact.h)("rect", {
      className: "uppy-ProviderIconBg",
      width: "32",
      height: "32",
      rx: "16",
      fill: "#0E71EB"
    }), (0, _preact.h)("g", {
      fill: "none",
      fillRule: "evenodd"
    }, (0, _preact.h)("path", {
      fill: "#fff",
      d: "M29,31H14c-1.657,0-3-1.343-3-3V17h15c1.657,0,3,1.343,3,3V31z",
      style: {
        transform: 'translate(-5px, -5px) scale(0.9)'
      }
    }), (0, _preact.h)("polygon", {
      fill: "#fff",
      points: "37,31 31,27 31,21 37,17",
      style: {
        transform: 'translate(-5px, -5px) scale(0.9)'
      }
    })));

    this.provider = new _companionClient.Provider(uppy, {
      companionUrl: this.opts.companionUrl,
      companionHeaders: this.opts.companionHeaders,
      companionKeysParams: this.opts.companionKeysParams,
      companionCookiesRule: this.opts.companionCookiesRule,
      provider: 'zoom',
      pluginId: this.id
    });
    this.defaultLocale = locale;
    this.i18nInit();
    this.title = this.i18n('pluginNameZoom');
    this.onFirstRender = this.onFirstRender.bind(this);
    this.render = this.render.bind(this);
  }

  install() {
    this.view = new _providerViews.ProviderViews(this, {
      provider: this.provider
    });
    const {
      target
    } = this.opts;

    if (target) {
      this.mount(target, this);
    }
  }

  uninstall() {
    this.view.tearDown();
    this.unmount();
  }

  onFirstRender() {
    return Promise.all([this.provider.fetchPreAuthToken(), this.view.getFolder()]);
  }

  render(state) {
    return this.view.render(state);
  }

}

Zoom.VERSION = packageJson.version;
module.exports = Zoom;